import React from "react";
import { Route, Redirect, useLocation } from "react-router-dom";
import { useAuthContext } from "utils/contexts";

export default function ProtectedRoute({ component: Component, authenticate, ...rest }) {
  const { pathname, search, hash } = useLocation(); // Include hash
  const { isAuthenticated } = useAuthContext();

  if (authenticate) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={`/login?redirect=${pathname}${search}${hash}`} // Preserve hash
            />
          )
        }
      />
    );
  } else {
    let redirect;
    if (search) {
      redirect = new URLSearchParams(search).get("redirect");
    }

    return (
      <Route
        {...rest}
        render={(props) =>
          !isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={redirect ? `${redirect}${hash}` : `/${hash}`} // Preserve hash
            />
          )
        }
      />
    );
  }
}

ProtectedRoute.defaultProps = {
  authenticate: true,
};