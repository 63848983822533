import React, { useState, useEffect } from 'react';
import Page from 'components/Page';
import { API } from 'aws-amplify';
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  Row,
  Col
} from 'reactstrap'
import { useHistory } from 'react-router-dom';
import PageSpinner from 'components/PageSpinner';
import * as moment from 'moment';
import { BadgesCatSortableTable } from 'components/SortableTable';

const BadgesCategoryPage = () => {
  const history = useHistory();

  const [badges, setBadges] = useState([]);

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    const loadBadges = () => {
      return API.get("careadmin", "/careadmin/badges/category");
    }

    const onLoad = async () => {
      setIsloading(true);

      const badges = await loadBadges();
      console.log(badges);
      setBadges(badges);

      setIsloading(false);
    }

    onLoad();
  },[]);

  /* const onCreateNew = () => {
    history.push("/badges/new");
  }

  const onAssign = () => {
    history.push("/badges/assign");
  } */

  const headers = ["Id", "Name", "Created At", "Created By"];

  return (
    <Page
      className="BadgesCategoryPage"
      title="Badges Category"
      breadcrumbs={[{ name: 'Badges Category', active: true }]}
    >
      <Card>
        <CardHeader>
          <Row>
            <Col md={9} className="my-auto">
              Badges Category
            </Col>
            <Col className="text-right">
              <Button color="primary" onClick={() => history.push("/badges_category/new")}>Create New</Button>
            </Col>
          </Row>
        </CardHeader>
        {
          isLoading ? <div className="py-2"><PageSpinner /></div> : (
            <CardBody>
              <BadgesCatSortableTable
                headers={ headers }
                values={ badges }
              />
            </CardBody>
          )
        } 
      </Card>

    </Page>
  )
}

export default BadgesCategoryPage;