import Avatar from 'components/Avatar';
import { UserCard } from 'components/Card';
// import Notifications from 'components/Notifications';
// import SearchInput from 'components/SearchInput';
// import { notificationsData } from 'demos/header';
// import withBadge from 'hocs/withBadge';
import React from 'react';
import {
  MdClearAll,
  MdExitToApp,
  MdHelp,
  MdInsertChart,
  MdMessage,
  MdNotificationsActive,
  MdNotificationsNone,
  MdPersonPin,
  MdSettingsApplications,
} from 'react-icons/md';
import {
  Button,
  ListGroup,
  ListGroupItem,
  // NavbarToggler,
  Nav,
  Navbar,
  NavItem,
  NavLink,
  Popover,
  PopoverBody,
} from 'reactstrap';
import {
  CentreList
} from 'utils/constants'
import bn from 'utils/bemnames';
import { Auth } from 'aws-amplify';
import { AuthContext, ProfileContext } from 'utils/contexts';

const bem = bn.create('header');

// const MdNotificationsActiveWithBadge = withBadge({
//   size: 'md',
//   color: 'primary',
//   style: {
//     top: -10,
//     right: -10,
//     display: 'inline-flex',
//     justifyContent: 'center',
//     alignItems: 'center',
//   },
//   children: <small>5</small>,
// })(MdNotificationsActive);

class Header extends React.Component {
  state = {
    isOpenNotificationPopover: false,
    isNotificationConfirmed: false,
    isOpenUserCardPopover: false,
  };

  // toggleNotificationPopover = () => {
  //   this.setState({
  //     isOpenNotificationPopover: !this.state.isOpenNotificationPopover,
  //   });

  //   if (!this.state.isNotificationConfirmed) {
  //     this.setState({ isNotificationConfirmed: true });
  //   }
  // };

  toggleUserCardPopover = () => {
    this.setState({
      isOpenUserCardPopover: !this.state.isOpenUserCardPopover,
    });
  };

  searchCentre = (_id) => {
    var result = "-";
    CentreList.forEach((centre) => {
      if (centre.id == _id){
        result = centre.centreName;
      }
    })
    return result;
  }

  handleSidebarControlButton = event => {
    event.preventDefault();
    event.stopPropagation();

    document.querySelector('.cr-sidebar').classList.toggle('cr-sidebar--open');
  };

  handleLogout = async () => {
    await Auth.signOut();
    this.toggleUserCardPopover();
    this.context.setIsAuthenticated(false);
  }

  render() {
    const { isNotificationConfirmed } = this.state;

    return (
      <Navbar light expand className={bem.b('bg-white')}>
      <Nav navbar className="mr-2">
        <Button outline onClick={this.handleSidebarControlButton}>
          <MdClearAll size={25} />
        </Button>
      </Nav>
      <Nav navbar className="mr-2">
        {
          this.context.isAuthenticated &&
            <div className='font-weight-bold care-center-header'>
            <ProfileContext.Consumer>
            {
              profileContext => (
                this.searchCentre(profileContext.profile.centre)
              )
            }
            </ProfileContext.Consumer>
            </div>
        }
      </Nav>
      {/* <Nav navbar>
        <SearchInput />
      </Nav> */}

      <Nav navbar className={bem.e('nav-right')}>
        {/* <NavItem className="d-inline-flex">
          <NavLink id="Popover1" className="position-relative">
            {isNotificationConfirmed ? (
              <MdNotificationsNone
                size={25}
                className="text-secondary can-click"
                onClick={this.toggleNotificationPopover}
              />
            ) : (
              <MdNotificationsActiveWithBadge
                size={25}
                className="text-secondary can-click animated swing infinite"
                onClick={this.toggleNotificationPopover}
              />
            )}
          </NavLink>
          <Popover
            placement="bottom"
            isOpen={this.state.isOpenNotificationPopover}
            toggle={this.toggleNotificationPopover}
            target="Popover1"
          >
            <PopoverBody>
              <Notifications notificationsData={notificationsData} />
            </PopoverBody>
          </Popover>
        </NavItem> */}

        <NavItem>
          <NavLink id="Popover2">
            <Avatar
              onClick={this.toggleUserCardPopover}
              className="can-click"
            />
          </NavLink>

          {this.context.isAuthenticated && 
            <Popover
              placement="bottom-end"
              isOpen={this.state.isOpenUserCardPopover}
              toggle={this.toggleUserCardPopover}
              target="Popover2"
              className="p-0 border-0"
              style={{ minWidth: 250 }}
            >
              <ProfileContext.Consumer>
              {
                profileContext => (
                  <PopoverBody className="p-0 border-light">
                  <UserCard
                    title={`${profileContext.profile.first_name} ${profileContext.profile.last_name}`}
                    subtitle={profileContext.profile.email}
                    text="Last updated 3 mins ago"
                    className="border-light"
                  >
                    <ListGroup flush>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdPersonPin /> Profile
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light" onClick={this.props.setSetting}>
                        <MdSettingsApplications /> Setting
                      </ListGroupItem>
                      {/* <ListGroupItem tag="button" action className="border-light">
                        <MdInsertChart /> Stats
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdMessage /> Messages
                      </ListGroupItem>
                      <ListGroupItem tag="button" action className="border-light">
                        <MdHelp /> Help
                      </ListGroupItem> */}
                      <ListGroupItem tag="button" action className="border-light" onClick={this.handleLogout}>
                        <MdExitToApp /> Signout
                      </ListGroupItem>
                    </ListGroup>
                  </UserCard>
                </PopoverBody>
                )
              }
              </ProfileContext.Consumer>
            </Popover>
          }
        </NavItem>
      </Nav>
    </Navbar>    
    );
  }
}

Header.contextType = AuthContext;

export default Header;
