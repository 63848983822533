
import imageVital from 'assets/img/diary/icon_box_vitals.png';
import imageVitalBP from 'assets/img/diary/vitalx_bloodpressure.png';
import imageVitalGlucose from 'assets/img/diary/vitalx_glucocheck.png';
import imageVitalHeartRate from 'assets/img/diary/vitalx_heartrate.png';
import imageVitalRespiration from 'assets/img/diary/vitalx_respiration.png';
import imageVitalSleep from 'assets/img/diary/vitalx_sleep.png';
import imageVitalSpO2 from 'assets/img/diary/vitalx_spo2.png';
import imageVitalTemperature from 'assets/img/diary/vitalx_temperature.png';
import imageVitalWeight from 'assets/img/diary/vitalx_weight.png';
import imageAdl from 'assets/img/diary/icon_box_adls.png';
import imageIntake from 'assets/img/diary/icon_box_intake.png';
import imageMental from 'assets/img/diary/icon_box_mental.png';
import imageGCS from 'assets/img/diary/icon_box_gcs.png';
import imageCondition from 'assets/img/diary/icon_box_conditions.png';
import imageNotes from 'assets/img/diary/icon_box_notes.png';
import imageMedical from 'assets/img/diary/icon_box_medication_v2.png';
import countryFlags from 'country-flag-emoji-json';

export const TimesheetStatus = {
  open: "open",
  inProgress: "in progress",
  completed: "completed",
  approved: "approved",
  done: "done",
  cancelled: "cancelled",
  rejected: "rejected"
}

export const JobStatus = {
  draft: "draft",
  open: "open",
  inProgress: "in-progress",
  completed: "completed",
  cancelled: "cancelled",
  closed: "closed"
}

export const OrderStatus = {
  // open: "open",
  // onGoing: "ongoing",
  // completed: "completed",
  // done: "done",
  // cancelled: "cancelled"
  draft: "draft",
  open: "open",
  financeToInvoice: "finance to invoice",
  invoicing: "invoiced",
  onGoing: "ongoing",
  completed: "completed",
  cancelled: "cancelled"
}

export const InvoiceStatus = {
  DRAFT: "DRAFT",
  SUBMITTED: "SUBMITTED",
  AUTHORISED: "AUTHORISED",
  DELETED: "DELETED",
  VOIDED: "VOIDED",
  PAID: "PAID"
}

export const PanicStatus = {
  open: "Open",
  handling: "Handling",
  resolved: "Resolved",
  cancelled: "Cancelled",
  resolvedAll: "Resolved All",
  cancelAll: "Cancel All"
}

export const RoleType = {
  facility: "facility",
  homecare: "homecare",
  corporate: "corporate",
  superadmin: "superadmin",
  finance: "finance",

  //only shows dashboard
  dashboard: "dashboard",

  //cannot login
  customer: "customer",
  caregiver: "caregiver",
  deployment: "deployment",
}

export const IntegrationType = {
  biztory: "biztory"
}

export const IntegrationUrl = {
  biztory: "mycareconcierge.biztory.com.my"
}

export const IntegrationPath = {
  biztoryCreateInvoice: "/api_v1/sale",
  biztoryCreateCustomer: "/api_v1/customer",
  biztoryCreateCaregiver: "/api_v1/vendor",
  biztoryCreateExpense: "/api_v1/expense"
}

export const PanicResolvedList = ["Can't Sleep", "Medication", "Toilet", "Move Around", "Hungry", "Make a Call", "Thirsty", "Others"];
export const PanicCanelledList = ["Misclick","Others"];

export const AppointmentStatusList = ["open", "completed", "cancelled"];

export const JobRoleList = ["caregiver", "nurse", "physiotherapy"];

export const GenderList = ["male", "female"];
export const MaritalStatusList = ["single", "married", "divorced", "widowed", "others"];
export const RaceList = ["malay", "chinese", "indian", "others"];
export const ReligionList = ["islam", "buddhism", "christianity", "hinduism", "others"];
export const ProductTypeList = ["service", "consumable", "daily services", "hourly services", "transport"];
export const ProductCategoryList = {
  "daily services": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "hourly services": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "transport": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "service": ["homecare", "mansion", "komunecare", "residence", "academy"],
  "consumable": ["commerce", "komunecare", "residence", "academy"]
}
export const ProductSubCategoryList = {
  "homecare": ["caregiver", "nurse"],
  "mansion": [],
  "commerce": []
}
export const ProductStatusList = ['active', 'inactive'];

export const CentreList = [
  {
    id: 0,
    centreName: "Unassigned",
    label: "Unassigned",
    value: 0,
    isDisabled: false
  },
  {
    id: 1,
    centreName: "TM Gasing 136",
    label: "TM Gasing 136",
    value: 1,
    isDisabled: false
  },
  {
    id: 2,
    centreName: "TM Sri Aman",
    label: "TM Sri Aman",
    value: 2,
    isDisabled: false
  },
  {
    id: 3,
    centreName: "TM Gasing 60",
    label: "TM Gasing 60",
    value: 3,
    isDisabled: false
  },
  {
    id: 4,
    centreName: "Komune Care",
    label: "Komune Care",
    value: 4,
    isDisabled: false
  },
  {
    id: 5,
    centreName: "TPR Penang",
    label: "TPR Penang",
    value: 5,
    isDisabled: false
  },
  {
    id: 6,
    centreName: "HomeCare",
    label: "HomeCare",
    value: 6,
    isDisabled: false
  },
  {
    id: 7,
    centreName: "TM Ritchie",
    label: "TM Ritchie",
    value: 7,
    isDisabled: false
  },
  {
    id: 8,
    centreName: "Demo Company (Xero Development)",
    label: "Demo Company (Xero Development)",
    value: 8,
    isDisabled: false
  },
  {
    id: 9,
    centreName: "Angsana Home",
    label: "Angsana Home",
    value: 9,
    isDisabled: false
  },
  {
    id: 10,
    centreName: "Ara Woods",
    label: "Ara Woods",
    value: 10,
    isDisabled: false
  },
  {
    id: 11,
    centreName: "Jaya One Daycare",
    label: "Jaya One Daycare",
    value: 11,
    isDisabled: false
  },
  {
    id: 12,
    centreName: "Academy Care",
    label: "Academy Care",
    value: 12,
    isDisabled: false
  }
];

export const HomecareCentreFilter = [
  {
    id: 6,
    centreName: "HomeCARE"
  },
  {
    id: 0,
    centreName: "Unassigned"
  }
];

export const ZoneList = {
  //centre id: 1
  1: [
    {
      id: 1,
      zoneName: "S1"
    },
    {
      id: 2,
      zoneName: "S2"
    },
    {
      id: 3,
      zoneName: "S3"
    },
    {
      id: 4,
      zoneName: "S4"
    },
    {
      id: 5,
      zoneName: "S5"
    },
    {
      id: 6,
      zoneName: "S6"
    },
  ],
  //centre id: 2
  2: [
    {
      id: 7,
      zoneName: "S1 (Downstairs)"
    },
    {
      id: 8,
      zoneName: "S2 (Upstairs)"
    },
  ],
  //centre id: 3
  3: [
    {
      id: 9,
      zoneName: "58"
    },
    {
      id: 10,
      zoneName: "56"
    },
    {
      id: 11,
      zoneName: "54"
    },
  ]
};

export const YesNoOptions = [
  {
    id: 0,
    name: "No"
  },
  {
    id: 1,
    name: "Yes"
  }
];

export const MaintenanceStatusList = ["open", "in progress", "completed"];
export const ProfessionList = [
  "Artist",
  "Babysitter",
  "Butler",
  "Caregiver",
  "Chef",
  "Counsellor",
  "Dietitian",
  "Doctor",
  "Duty Manager",
  "Gardener",
  "Health Consultant",
  "Home chef",
  "Housekeeper",
  "Maid",
  "Manager",
  "Meal Planner",
  "Music therapist",
  "Nanny",
  "Nurse",
  "Nutritionist",
  "Performer",
  "Physiotherapist",
  "Program coordinator",
  "Retired Doctor",
  "Retired Nurse",
  "Retired therapist",
  "Singing Companion",
  "Traditional Chinese Medicine Doctor",
  "Traditional Chinese Medicine Health Pro",
  "Assistant Media Officer",
  "Occupational Therapist",
  "Chiropractor",
  "Intern (caregiver)",
  "Intern ( Medical Doctor)",
  "Intern (Staff Nurse)",
  "Intern ( Assistant Medical Officer)",
  "Clinical Instructor",
  "Trainer",
  "Program Director",
];
export const CaregiverStatusList = ["new", "pending verification", "active", "assessment kiv", "blacklisted", "disabled"];
export const CaregiverSourceList = ['Came for interview', 'Ex-work colleague', 'Facebook', 'Family', 'Flyer', 'Friend of friends', 'Friends', 'Instagram', 'Job portal', 'LinkedIn', 'Newspaper/Magazine', 'Others', 'Personal connection', 'Website'];
export const OrderStatusList = ["draft", "open", "finance to invoice", "invoiced", "ongoing", "completed", "cancelled"];

export const AllowanceList = [
  // "Transportation",
  "Meal Allowance",
  "Overtime",
  "COVID Test Kit",
  "Others (Please specify)"
];

export const PaymentStatus = {
  processed: "processed"
}

export const renderedStatus ={
  approve : "APPROVE",
  rejected: "REJECTED",
  cancel: "CANCEL"
}

export const UserType = {
  customer: "customer",
  care_pro: "care pro",
  careadmin: "careadmin"
}

export const App = {
  caremanagerportal: "Care Manager Portal",
  jomcare: "Jomcare"
}

const centreMap = CentreList.reduce((acc, centre) => {
  acc[centre.id] = centre.centreName;
  return acc;
}, {});

export const searchCentre = (_id) => {
  return centreMap[_id] || "-";
};

export const MaintenancePriorityList = [
  {
    id: 1,
    priority: "Critical"
  },
  {
    id: 2,
    priority: "High"
  },
  {
    id: 3,
    priority: "Medium"
  },
  {
    id: 4,
    priority: "Low"
  }
];

export const MaintenanceStatus = {
  open: "open",
  inProgress: "in progress",
  completed: "completed"
};

export const WorkType = ["Full Time", "Part Time"];

export const DiaryType = [
  'weight', 
  'glucose', 
  'heartrate', 
  'bloodpressure', 
  'sleep', 
  'temperature', 
  'note', 
  'spo2', 
  'adl', 
  'pain', 
  'input', 
  'output', 
  'mental', 
  'respiratory', 
  'gcs', 
  'medication_usage'
];

export const RoomServicesList = [
  {value: "alu", label: "ALU"},
  {value: "daycare", label: "Daycare"},
]

export const DiaryNameMapper = {
  weight: 'Weight', 
  glucose: 'Glucose', 
  heartrate: 'Heart Rate', 
  bloodpressure: 'Blood Pressure', 
  sleep: 'Sleep', 
  temperature: 'Temperature', 
  note: 'Note', 
  spo2: 'SPO2', 
  adl: 'ADLs', 
  pain: 'Pain', 
  input: 'Input', 
  output: 'Output', 
  mental: 'Mental', 
  respiratory: 'Respiratory', 
  gcs: 'Gcs', 
  medication_usage: 'Medication_usage'
};

export const DiaryImageMapper = {
  weight: imageVitalWeight,
  sleep: imageVitalSleep,
  temperature: imageVitalTemperature,
  bloodpressure: imageVitalBP,
  heartrate: imageVitalHeartRate,
  glucose: imageVitalGlucose,
  spo2: imageVitalSpO2,
  respiratory: imageVitalRespiration,
  adl: imageAdl,
  input: imageIntake,
  output: imageIntake,
  mental: imageMental,
  gcs: imageGCS,
  pain: imageCondition,
  note: imageNotes,
  medication_usage: imageMedical,
};

export const DiaryUnitMapper = {
  weight: "kg",
  sleep: "hours",
  temperature: "\u2103",
  bloodpressure: "mmHg",
  heartrate: "bpm",
  glucose: "mmol/L",
  spo2: "%",
  respiratory: "bpm",
};

export const gcsEye = ["Spontaneously", "To Voice", "To Pain", "No Response"];

export const gcsMotor = [
  "Orientated To Time, Place and Person",
  "Confused",
  "Inappropriate Words",
  "Incomprehensible Sounds",
  "No Response",
];

export const gcsVerbal = [
  "Obeys Commands",
  "Localizes Pain",
  "Withdrawal Form Pain",
  "Abnormal Flexion (Decorticate)",
  "Abnormal Extension (Decerebrate)",
  "No Response",
];

export const emojiMapper = {
  "11":"Content",
  "10":"Playful",
  "9":"Verry Happy",
  "8":"Happy",
  "7":"Restless",
  "6":"Sick",
  "5":"Angry",
  "4":"Annoyed",
  "3":"Confused",
  "2":"Sleepy",
  "1":"Upset",
  "0":"Sad",
};

export const inputMealList = ["1", "2", "3", "4", "5", "6"];
export const inputMealMapper = {
  "1":"Breakfast",
  "2":"Lunch",
  "3":"Tea Time",
  "4":"Dinner",
  "5":"Fruits",
  "6":"Snack",
}

export const inputTypeList = ["1", "2", "3"];
export const inputTypeMapper = {
  "1":"Normal",
  "2":"NGT",
  "3":"Peg Tube",
}

export const outputSimpleMapper = {
  1: "stool",
  2: "urine"
}
export const outputMealMapper = {
  1: {
    "name": "stool",
    "type": {
      1: "Normal",
      2: "Diaper",
      3: "Colostomy",
      4: "Ileostomy",
    },
    "amount": {
      1: "Large",
      2: "Moderate",
      3: "Small",
    },
    "color": {
      1: "Black",
      2: "Brown",
      3: "Light Brown",
      4: "Green",
      5: "Red",
      6: "Pink/Purple",
      7: "Grey",
      8: "Yellow",
      9: "Orange",
    },
    "pattern": {
      1: "Type 1 Very constipated Hard Lumps",
      2: "Type 2 Slightly constipated Lumpy & sausage",
      3: "Type 3 Sausage shape Crack surface",
      4: "Type 4 Soft sausage Snake, Normal",
      5: "Type 5 Soft blobs Clear-cut edges",
      6: "Type 6 Mushy consistency Ragged edges",
      7: "Type 7 Liquid consistency No solid pieces",
    }
  },
  2: {
    "name": "urine",
    "type": {
      1: "Normal",
      2: "Diaper",
      3: "CBD",
      4: "Urostomy",
    },
    "amount": {
      1: "Large",
      2: "Moderate",
      3: "Small",
    },
    "color": {
      1: "Clear",
      2: "LightYellow",
      3: "Yellow",
      4: "DarkYellow",
      5: "Amber",
      6: "Brown",
      7: "Red",
    },
    "pattern": {
    }
  }
}

export const adlList = [
  "activity",
  "ambulation",
  "bathing",
  "bedbath",
  "changeclothes",
  "changelinen",
  "exercise",
  "facemassage",
  "facial",
  "feedfood",
  "haircut",
  "headmassage",
  "massage",
  "nailcare",
  "shaving",
  "toiletassist",
  "turning",
  "diaperchange",
  "feeding",
  "hairshampoo",
  "footmassage",
  "putlotion",
  "lightstretching",
]

export const adlMapper = {
  "activity": "Activity",
  "ambulation": "Ambulation",
  "bathing": "Bathing",
  "bedbath": "Bed Bath",
  "changeclothes": "Change Clothes",
  "changelinen": "Change Linen",
  "exercise": "Exercise",
  "facemassage": "Face Massage",
  "facial": "Facial",
  "feedfood": "feed food",
  "haircut": "haircut",
  "headmassage": "head massage",
  "massage": "massage",
  "nailcare": "nailcare",
  "shaving": "shaving",
  "toiletassist": "toiletassist",
  "turning": "turning",
  "diaperchange": "diaperchange",
  "feeding": "feeding",
  "hairshampoo": "hairshampoo",
  "footmassage": "foot massage",
  "putlotion": "put lotion",
  "lightstretching": "light stretching"
}

export const painTypeList = [
  "no pain",
  "mild",
  "moderate",
  "severe pain",
  "very severe pain",
  "worst possible"
]

export const painBodyList = [  
  "head",
  "left neck",
  "right neck",
  "left shoulder",
  "right shoulder",
  "armpit",
  "chest ",
  "abdomen",
  "elbow",
  "upper arm",
  "lower arm",
  "hand",
  "private",
  "groin",
  "knee",
  "lower leg",
  "upper leg",
  "foot",
  "toes",
]

export const foodList = [  
  "Biscuit",
  "Bread",
  "Croissant",
  "Cereal",
  "Oat",
  "Coffee",
  "Egg",
  "Soup Noodle",
  "Fried Noodle",
  "Fried Rice",
  "Nasi Lemak",
  "Green Tea",
  "Horlicks",
  "Milo",
  "Tea",
  "Teh Tarik",
  "Mango Juice",
  "Orange Juice",
  "Red Apple",
  "Green Apple",
  "Orange",
  "Banana",
  "Lemon",
  "Mango",
  "Watermelon",
  "Pineapple",
  "Pumpkin",
  "Cake",
  "Cupcake",
  "Donut",
  "Puff",
  "Cone Icecream",
  "Stick Icecream",
  "Mushroom Soup",
  "Pumpkin Soup",
  "Red Bean Soup",
  "Porridge",
  "Salad Bowl",
  "Coke",
  "Pepsi",
  "Chicken",
  "Curry Fish",
  "Steam Fish",
  "Few Water",
  "Half Water",
  "Full Water",
  "Milk",
  "Rice",
  "Meat",
  "Pork",
  "Fried Egg",
  "Omelette",
  "Ulam",
  "Vegetables",
]

export const inputUnitsList = [
  "unit", "l", "ml"
] 

export const ancillaryServicesCategory = [
  "Medicine",
  "Medical Supply",
  "Miscellaneous",
  "Physiotherapy & Other Therapy",
  "Escort & Chaperone",
  "Transportation & Logistics",
]

export const cgRateCalculator = (_timeSheet) => {
  if((_timeSheet ?? undefined) === undefined)
    return undefined;
  if("cg_rate" in _timeSheet && "start_time" in _timeSheet && "end_time" in _timeSheet && "product_type" in _timeSheet){
    return cgRateCalculatorRaw(
      _timeSheet?.cg_rate ?? 0.00, 
      _timeSheet?.product_type, 
      _timeSheet?.start_time, 
      _timeSheet?.end_time);
  }else{
    alert("Timesheet error not contain cg_rate, start_time, end_time OR product_type");
  }
  return NaN;
}
export const cgRateCalculatorRaw = (cg_rate, product_type, start_time, end_time) => {
  
  function getTotalSeconds(start, end) {
    if(start === "" || start === undefined || end === "" || end === undefined )
      return 0;
    const startArray = start.split(":");
    const endArray = end.split(":");
    if(startArray.length < 2 && endArray.length < 2 )
      return 0;
    
    const startSecond = (Number(startArray[0])*60*60) + (Number(startArray[1])*60);
    let endSecond = (Number(endArray[0])*60*60) + (Number(endArray[1])*60);
    if(endSecond < startSecond || endSecond == startSecond){
      endSecond += 86400
    }
    return endSecond - startSecond;
  }  

  function toHoursAndMinutes(totalSeconds) {
    const totalMinutes = Math.floor(totalSeconds / 60);
  
    const seconds = totalSeconds % 60;
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
  
    return { h: hours, m: minutes, s: seconds };
  }  

  if (product_type === "comsumable" || product_type === "consumable"){
    return cg_rate ? parseFloat(cg_rate) : 0.00;
  }else if (product_type === "hourly services"){
    const sec = getTotalSeconds(start_time, end_time);
    const totalHours = toHoursAndMinutes(sec);
    return (
      cg_rate?
      (totalHours.h * parseFloat(cg_rate)) + (totalHours.m * parseFloat(cg_rate) / 60) : 0.00
    );
  }else if (product_type === "service" || product_type === "daily services"){
    return cg_rate ? parseFloat(cg_rate) : 0.00;
  }
}

export const tryDecodeURI = (text) => {
  let decodedValue = text;
  try {
    decodedValue = decodeURI(text);
  } catch (error) {
    // If decoding fails, keep the original text
  }
  return decodedValue; 
};

export const getNextMonthFirstDay = (date) => {
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);
  return nextMonth;
};

export const getNextMonthLastDay = (date) => {
  const nextMonth = new Date(date.getFullYear(), date.getMonth() + 2, 0);
  return nextMonth;
}

export const getRawPhoneNumber = (mobile) => {
  if(mobile === null) return null;
  const rawMobile = mobile.replaceAll(/\D/g,'');
  const PHONE_REGEX3 = /^01[0-9]*$/
  if(PHONE_REGEX3.test(rawMobile)){
    return '+6' + rawMobile;
  }
  return '+' + rawMobile;
}

export const EmploymentType = [
  "Local", 
  "F - RTK2.0", 
  "F - Kosong", 
  "F - Refugee", 
  "F - Cleaner Outsource", 
  "F - Other Visa", 
  "F - CCR", 
  "PT / Locum", 
  "Spouse Visa",
  "Permanent Resident"];

export const bankNameList = [
  "Evolet",
  "Jompay",
  "InstaPay",
  "Bank Rakyat Berhad",
  "Bangkok Bank Berhad",
  "Bank of America",
  "AmBank Berhad",
  "Bank Simpanan Nasional Berhad",
  "Alliance Bank Berhad",
  "Standard Chartered Bank Berhad",
  "Citibank",
  "RHB Bank Berhad",
  "Deutsche Bank (M) Bhd",
  "HSBC Bank Berhad",
  "Hong Leong Bank Berhad/ Hong Leong Finance",
  "United Overseas Bank (M) Bhd",
  "Malayan Banking Berhad",
  "OCBC Bank Berhad",
  "Affin Bank Berhad",
  "Public Bank Berhad/ Public Finance Berhad",
  "CIMB Bank Berhad",
  "Bank Muamalat Malaysia Berhad",
  "Bank of China (Malaysia) Berhad",
  "Bank Islam Malaysia Berhad",
  "The Royal Bank of Scotland Bhd",
  "Kuwait Finance House",
  "JP Morgan Chase",
  "Agro Bank",
  "Sumitomo Mitsui Banking Corporation (M) Bhd",
  "Bank of Tokyo-Mitsubishi UFJ (Malaysia) Berhad",
  "Al-Rajhi Banking and Investment Corporation (Malaysia) Berhad",
  "Indust & Comm Bank of China (M) Berhad",
  "BNP Paribas (M) Bhd / BNP Paribas (Islamic)",
  "China Construction Bank (Malaysia) Berhad",
  "Mizuho Bank (M) Berhad",
  "MBSB"
]


export const RelationshipAccessLevel = {
  PENDING_APPROVAL: 0,
  VIEWER: 1,
  EDITOR: 2,
  ADMIN: 3,
  CARE_MASTER: 98,
  CARE_OWNER: 99,
};

export const RelationshipAccessLevelDefinition = {
  0: {id: 0, name: "pendding", text: "Pendding Approve", desc: "The relationship status not approved."},
  1: {id: 1, name: "read", text: "Viewer", desc: "View all the customer details."},
  2: {id: 2, name: "write", text: "Editor", desc: "Can edit the customer all data details."},
  3: {id: 3, name: "admin", text: "Admin", desc: "Admin able Manipulation the other relationship changes"},
  98: {id: 98, name: "master", text: "Care Master", desc: "Care Master, and Payee of the customer"},
  99: {id: 99, name: "owner", text: "Care Master owner", desc: "Care Owner, Self Owner, Care Master, and Payee"},
}

export const relationshipIds = {
  1: 'Mother',
  2: 'Father',
  3: 'Daughter',
  4: 'Son',
  5: 'Sister',
  6: 'Brother',
  7: 'Aunt',
  8: 'Uncle',
  9: 'Cousin (Female)',
  10: 'Cousin (Male)',
  11: 'Grandmother',
  12: 'Grandfather',
  13: 'Niece',
  14: 'Nephew',
  15: 'Stepfather',
  16: 'Stepmother',
  17: 'Stepdaughter',
  18: 'Stepson',
  19: 'Sister-in-law',
  20: 'Brother-in-law',
  21: 'Mother-in-law',
  22: 'Father-in-law',
  23: 'Granddaughter',
  24: 'Grandson',
  25: 'Great-grandmother',
  26: 'Great-grandfather',
  27: 'Daughter-in-law',
  28: 'Son-in-law',
  29: 'Wife',
  30: 'Husband',
  31: 'Other',
};

export const relationships = {
  1: [4, 3, 31],
  2: [4, 3, 31],
  3: [1, 2, 31],
  4: [1, 2, 31],
  5: [6, 31],
  6: [5, 31],
  7: [13, 14, 31],
  8: [13, 14, 31],
  9: [10, 31],
  10: [9, 31],
  11: [24, 23, 31],
  12: [24, 23, 31],
  13: [7, 8, 31],
  14: [7, 8, 31],
  15: [17, 18, 31],
  16: [17, 18, 31],
  17: [15, 16, 31],
  18: [15, 16, 31],
  19: [20, 31],
  20: [19, 31],
  21: [27, 28, 31],
  22: [27, 28, 31],
  23: [11, 12, 31],
  24: [11, 12, 31],
  25: [26, 24, 31],
  26: [25, 24, 31],
  27: [21, 22, 31],
  28: [21, 22, 31],
  31: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 31],
};

// Convert relationship data to select options
export const relationshipOptions = Object.keys(relationshipIds).map(id => ({
  value: id,
  label: relationshipIds[id]
}));

export const marketingChannelList = [
  {
    label: "Marketing - Meta",
    value: "Marketing - Meta"
  },
  {
    label: "Marketing - Google Form",
    value: "Marketing - Google Form"
  },
  {
    label: "Marketing - Others",
    value: "Marketing - Others"
  },
  {
    label: "Business Development",
    value: "Business Development"
  },
  {
    label: "SBU",
    value: "SBU"
  },
]

export const isFacilityDef = (_order) => {
  return !((_order?.centre ?? _order?.center ?? 0) === 6);
};
 // List of country codes to pin at the top
 const pinnedCodes = ["MY", "SG", "CN", "PH", ];

 // Generate nationality options with pinned items
 export const NationalityOptions = [
   // First, map over pinned codes and extract the matching countries
   ...pinnedCodes
     .map((code) => countryFlags.find((country) => country.code === code))
     .filter(Boolean) // Remove any undefined matches
     .map((country) => ({
       value: country.name,
       label: `${country.emoji} ${country.name}`,
     })),
   // Then, add the rest of the countries that are not pinned
   ...countryFlags
     .filter((country) => !pinnedCodes.includes(country.code)) // Exclude pinned countries
     .map((country) => ({
       value: country.name,
       label: `${country.emoji} ${country.name}`,
     })),
 ];