import React, { useState, useEffect } from 'react';
import { MainLayout, ProtectedRoute } from './components/Layout';
import PageSpinner from 'components/PageSpinner';
import componentQueries from 'react-component-queries';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { AuthContext, ProfileContext, AlertProvider } from 'utils/contexts';
import { Auth, API } from 'aws-amplify';
import './styles/reduction.scss';
import BadgesCategoryPage from 'pages/badges_category/BadgesCategoryPage';

const AuthPage = React.lazy(() => import('pages/AuthPage'));
const DashboardPage = React.lazy(() => import('pages/dashboards/DashboardPage'));
const PanicQueuePage = React.lazy(() => import('pages/dashboards/PanicDashboardPage'));
const PanicDashboardPage = React.lazy(() => import('pages/dashboards/PanicDashboardPage'));

const CustomersPage = React.lazy(() => import('pages/customers/CustomersPage'));
const CustomerViewPage = React.lazy(() => import('pages/customers/CustomerViewPage'));
const CustomerViewPage2 = React.lazy(() => import('pages/customers/Customer'));
const CustomerNewPage_new = React.lazy(() => import('pages/customers/CustomerNewPage_new'));
//const CustomerNewPage_old = React.lazy(() => import('pages/customers/CustomerNewPage_old'));

const CaregiverNewPage = React.lazy(() => import('pages/caregivers/CaregiverNewPage'));
const CaregiversPage = React.lazy(() => import('pages/caregivers/CaregiversPage'));
const CaregiverViewPage = React.lazy(() => import('pages/caregivers/CaregiverViewPage'));
const CaregiverViewPage2 = React.lazy(() => import('pages/caregivers/Caregiver'));

const OrdersPage = React.lazy(() => import('pages/orders/OrdersPage'));
const OrdersPageGeneral = React.lazy(() => import('pages/orders/OrdersPageGeneral'));
const OrderNewPage = React.lazy(() => import('pages/orders/OrderNewPage'));
const OrderViewPage_old = React.lazy(() => import('pages/old_orders/Order'));
const OrderViewPage_new = React.lazy(() => import('pages/orders/OrderPro'));

const ProductsPage = React.lazy(() => import('pages/products/ProductsPage'));
const ProductNewPage = React.lazy(() => import('pages/products/ProductNewPage'));
const ProductViewPage = React.lazy(() => import('pages/products/ProductViewPage'));

const JobsPage = React.lazy(() => import('pages/jobs/JobsPage'));
const JobNewPage = React.lazy(() => import('pages/jobs/JobNewPage'));
const JobViewPage = React.lazy(() => import('pages/jobs/JobViewPage'));

const BadgesPage = React.lazy(() => import('pages/badges/BadgesPage'));
const BadgeNewPage = React.lazy(() => import('pages/badges/BadgeNewPage'));
const BadgesAssignPage = React.lazy(() => import('pages/badges/BadgesAssignPage'));
const BadgeViewPage = React.lazy(() => import('pages/badges/BadgeViewPage'));

const BadgesCategoryNewPage = React.lazy(() => import('pages/badges_category/BadgesCategoryNewPage'));
const BadgesCategoryViewPage = React.lazy(() => import('pages/badges_category/BadgesCategoryViewPage'));

const AppointmentsPage = React.lazy(() => import('pages/appointments/AppointmentsPage'));
const AppointmentViewPage = React.lazy(() => import('pages/appointments/AppointmentViewPage'));

const RoomConfigurationPage = React.lazy(() => import('pages/room_config/RoomConfigPage'));
const RoomNewPage = React.lazy(() => import('pages/room_config/RoomNewPage'));
const RoomConfigViewPage = React.lazy(() => import('pages/room_config/RoomConfigViewPage'));

const PeoplePage = React.lazy(() => import('pages/settings/people/PeoplePage'));
const PeopleSettingPage = React.lazy(() => import ('pages/settings/people/PeopleSettingPage'));
const ChangePasswordPage = React.lazy(() => import('pages/settings/ChangePasswordPage'));
const RosterShiftPage = React.lazy(()=> import('pages/settings/roster/RosterShiftPage'));
const RosterShiftNewPage = React.lazy(() => import('pages/settings/roster/RosterShiftNewPage'));
const RosterTaskPage = React.lazy(()=> import('pages/settings/roster/RosterTaskPage'));
const RosterTaskNewPage = React.lazy(() => import('pages/settings/roster/RosterTaskNewPage'));
const ZonePage = React.lazy(() => import('pages/settings/zone/ZoneListPage'));
const ZoneNewPage = React.lazy(() => import('pages/settings/zone/ZoneNewPage'));
const ZoneViewPage = React.lazy(() => import('pages/settings/zone/ZoneViewPage'));


const FacilityOrderPage = React.lazy(() => import('pages/facility_management/orders/FacilityOrdersPage'));
const FacilityOrderNewPage = React.lazy(() => import('pages/facility_management/orders/FacilityOrdersNewPage'));
const FacilityOrderViewPage = React.lazy(() => import('pages/facility_management/orders/FacilityOrdersViewPage'));
// New order view page
const FacilityOrderViewPage2 = React.lazy(() => import('pages/facility_management/orders/FacilityOrders'));
// const FacilityOrderActivePage = React.lazy(() => import('pages/facility_management/orders/FacilityOrderActive'));
// const FacilityOrderUnpaidPage = React.lazy(() => import('pages/facility_management/orders/FacilityOrderUnpaid'));

const FacilityAnalytics = React.lazy(() => import('pages/facility_management/analytics/AnalyticsPage'));
const FacilityBilling = React.lazy(() => import('pages/facility_management/billing/BillingPage'));
const FacilityBillingViewPage = React.lazy(() => import('pages/facility_management/billing/BillingViewPage'));
const FacilityInventory = React.lazy(() => import('pages/facility_management/inventory/InventoryPage'));
const FacilityMaintenance = React.lazy(() => import('pages/facility_management/maintenance/MaintenancePage'));
const FacilityPanicDashboard = React.lazy(() => import('pages/facility_management/panic_dashboard/PanicDashboardPage'));
const FacilityRostering = React.lazy(() => import('pages/facility_management/rostering/RosterViewPage'));
//const FacilityCaregiver = React.lazy(()=> import('pages/facility_management/caregiver/FacilityCaregiverPage'));
const FacilityCustomer = React.lazy(()=> import('pages/facility_management/customer/FacilityCustomerPage'));
const FacilityMaintenanceViewPage = React.lazy(() => import('pages/facility_management/maintenance/MaintenanceViewPage'));
const FacilityMaintenanceNewPage = React.lazy(() => import('pages/facility_management/maintenance/MaintenanceNewPage'));
const FacilityAlertCallConfig = React.lazy(() => import("pages/facility_management/alert_call_config/AlertCallConfig"));
const FacilityRoomConfiguration = React.lazy(() => import('pages/facility_management/room_config/FacilityRoomConfigPage'));


const CareplanNewPage = React.lazy(() => import('pages/careplan/CareplanNewPage'));
const CareplanDetailsPage = React.lazy(() => import('pages/careplan/CareplanDetailsPage'));
const CareplanPage = React.lazy(() => import('pages/careplan/CareplanPage'));
const CareplanViewPage = React.lazy(() => import('pages/careplan/CareplanViewPage'));
const CareplanTaskPage = React.lazy(() => import('pages/careplan/CareplanTaskPage'));
const CareplanDetailsNewPage = React.lazy(() => import('pages/careplan/CareplanDetailsNewPage'));
const CareplanTaskNewPage = React.lazy(() => import('pages/careplan/CareplanTaskNewPage'));



const WorkExperienceNewPage = React.lazy(() => import('pages/work_experience/WorkExperienceNewPage'));
const WorkExperienceListPage = React.lazy(() => import('pages/work_experience/WorkExperienceListPage'));
const WorkExperienceList2Page = React.lazy(() => import('pages/work_experience/WorkExperienceListPage'));
const WorkExperienceViewPage = React.lazy(() => import('pages/work_experience/WorkExperienceView'));

const DigitalDiaryPage = React.lazy(() => import('pages/digital_diary/DigitalDiaryPage'))

const SalaryListingPage = React.lazy(() => import('pages/salary_listing/SalaryListingPage'));

const HomecareDashboardPage = React.lazy(() => import('pages/homecare/HomecareDashboardPage'));
const FacilityDashboardPage = React.lazy(() => import('pages/facility_management/dashboard/FacilityDashboard'));
const AncillaryServicesPage = React.lazy(() => import('pages/ancillary_services/AncillaryServicesPages'));
const AncillaryServicesPageKlangValley = React.lazy(() => import('pages/ancillary_services/AncillaryServicesPages_klangValley'));
const MedicalConditionSettingPage = React.lazy(() => import('pages/medical_condition/MedicalConditionSettingPages'));

const AncillaryListingPage = React.lazy(() => import('pages/ancillary_services/AncillaryListPage'));

const ResidentAdmissionPage = React.lazy(() => import('pages/resident_admission/ResidentAdmissionPage'));

function App({ breakpoint }) {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [profile, setProfile] = useState({});

  useEffect(() => {
    const loadProfile = (username) => {
      return API.get("careadmin", `/careadmin/${username}?type=login`);
    }

    const onLoad = async () => {
      try {
        // Aloysius advise {Luk}: Here to determine to login session
        await Auth.currentSession();
        //  Check protected route nested if clause condition in regard of isAuthenticated Provider
        setIsAuthenticated(true);
  
        const user = await Auth.currentAuthenticatedUser();

        let emailValidator = /^([\w-]+(?:\.[\w-]+)*)@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$/i

        if(!emailValidator.test(user.username)){
          const profile = await loadProfile(user.attributes.email);
          setProfile(profile[0]);

          window.localStorage.setItem('profile', JSON.stringify(profile[0]));
        } else{
          const profile = await loadProfile(user.username);
          setProfile(profile[0]);

          window.localStorage.setItem('profile', JSON.stringify(profile[0]));
        }

        // console.log(profile[0]); 
      }
      catch(e) {
        if (e !== 'No current user') {
          //alert("onLoad error - " + e);
          console.warn("onLoad error - " + e);
        }
      }
    }

    onLoad();
  }, []);
  
  return (
    <BrowserRouter>
      <Switch>
        <AlertProvider>
        <AuthContext.Provider value={{ isAuthenticated, setIsAuthenticated, setProfile }}>
        <ProfileContext.Provider value={{ profile, setProfile }}>
          <MainLayout breakpoint={ breakpoint }>
            <React.Suspense fallback={ <PageSpinner /> }>
              {/* <Route exact path="/login" component={ AuthPage } /> */}
              <ProtectedRoute exact path="/login" component={ AuthPage } authenticate={ false } />
              <ProtectedRoute exact path="/forgotpassword" component={ AuthPage } authenticate={ false } />
              <ProtectedRoute exact path="/" component={ PanicQueuePage } />
              <ProtectedRoute exact path="/dashboard" component={ PanicQueuePage } />
              <ProtectedRoute exact path="/customers" component={ CustomersPage } />
              <ProtectedRoute exact path="/customers/new" component={ CustomerNewPage_new } />
              <ProtectedRoute exact path="/customers/:id([0-9]*)" component={ CustomerViewPage2 } />
              <ProtectedRoute exact path="/customers2/:id([0-9]*)" component={ CustomerViewPage } />
              <ProtectedRoute exact path="/:role(caregivers|facility_caregiver|deployment_caregiver)-:type(all|new|pending_verification|active|kiv|blacklisted|disabled|available|on_duty)" component={ CaregiversPage } />
              <ProtectedRoute exact path="/caregivers/new" component={ CaregiverNewPage } />
              <ProtectedRoute exact path="/:name(caregivers)/:id([0-9]*)" component={ CaregiverViewPage2 } />
              <ProtectedRoute exact path="/:name(caregivers_old)/:id([0-9]*)" component={ CaregiverViewPage } />
              <ProtectedRoute exact path="/orders" component={ OrdersPage } />
              <ProtectedRoute path="/orders/new/:id([0-9]*)?/:customer_name?" component={ OrderNewPage } />
              <ProtectedRoute exact path="/orders-old/:id([0-9]*)" component={ OrderViewPage_old } />
              <ProtectedRoute exact path="/:type(orders|facility_orders)/:id([0-9]*)" component={ OrderViewPage_new } />
              <ProtectedRoute exact path="/:general_type(orders|facility_orders)/:type(completed|unpaid|cancelled|active|all|cid)/:id([0-9]*)?/:customer_name?/:centre?" component={ OrdersPageGeneral } />
              <ProtectedRoute exact path="/orders-old/:type(completed|unpaid|cancelled|active|all|cid)/:id([0-9]*)?/:customer_name?/:centre?" component={ OrdersPage } />
              <ProtectedRoute exact path="/products" component={ ProductsPage } />
              <ProtectedRoute path="/products/new" component={ ProductNewPage } />
              <ProtectedRoute exact path="/products/:id([0-9]*)" component={ ProductViewPage } />
              <ProtectedRoute exact path="/jobs-:type(draft|open|all|completed|inprogress|closed|cancelled|oid)/:id([0-9]*)?" component={ JobsPage } />
              <ProtectedRoute path="/jobs/new/:oid?" component={ JobNewPage } />
              <ProtectedRoute exact path="/jobs/:id([0-9]*)" component={ JobViewPage } />
              <ProtectedRoute exact path="/:type(badges)" component={ BadgesPage } />
              <ProtectedRoute path=":type(badges)/new" component={ BadgeNewPage } />
              <ProtectedRoute path="/badges/assign/:id([0-9]*)?" component={ BadgesAssignPage } />
              <ProtectedRoute exact path=":type(badges)/:id([0-9]*)" component={ BadgeViewPage } />
              <ProtectedRoute exact path="/appointments" component={ AppointmentsPage } />
              <ProtectedRoute exact path="/appointments/:id([0-9]*)" component={ AppointmentViewPage } />
              <ProtectedRoute exact path="/:type(badges_category)" component={ BadgesCategoryPage } />
              <ProtectedRoute exact path="/:type(badges_category)/new" component={ BadgesCategoryNewPage } />
              <ProtectedRoute exact path="/:type(badges_category)/:id([0-9]*)" component={ BadgesCategoryViewPage} />
              <ProtectedRoute exact path="/settings/people" component={ PeoplePage } />
              <ProtectedRoute exact path="/settings/changepassword" component={ ChangePasswordPage } />
              <ProtectedRoute exact path="/settings/people/:id([0-9]*)" component= { PeopleSettingPage } />
              <ProtectedRoute exact path="/facility_analytics" component= { FacilityAnalytics } />

              <ProtectedRoute exact path="/facility_orders-old/:type(completed|unpaid|cancelled|active|all)" component={ FacilityOrderPage } />
              <ProtectedRoute exact path="/facility_orders/new/:id([0-9]*)?/:customer_name?" component={ FacilityOrderNewPage } />
              <ProtectedRoute exact path="/facility_orders-old/:id([0-9]*)" component={ FacilityOrderViewPage2 } />
              <ProtectedRoute exact path="/facility_billing" component= { FacilityBilling } />
              <ProtectedRoute exact path="/facility_billing/:id" component= { FacilityBillingViewPage } />
              <ProtectedRoute exact path="/facility_inventory" component= { FacilityInventory } />
              <ProtectedRoute exact path="/facility_maintenance" component= { FacilityMaintenance } />
              <ProtectedRoute exact path="/facility_panic_dashboard" component= { PanicDashboardPage } />
              <ProtectedRoute exact path="/facility_rostering" component= { FacilityRostering } />
              <ProtectedRoute exact path="/facility_maintenance/:id([0-9]*)" component= { FacilityMaintenanceViewPage } />
              <ProtectedRoute exact path="/facility_maintenance/new" component= { FacilityMaintenanceNewPage } />

              <ProtectedRoute exact path="/careplans/:cust_id([0-9]*)/new" component= { CareplanNewPage } />
              <ProtectedRoute exact path="/careplans/:cust_id([0-9]*)" component= { CareplanPage } />
              <ProtectedRoute exact path="/careplans/:cust_id([0-9]*)/view/:id([0-9]*)" component= { CareplanViewPage } />
              <ProtectedRoute exact path="/careplans/:name(report)/:cust_id([0-9]*)/:id([0-9]*)/new/:cust_name" component= { CareplanDetailsNewPage } />
              <ProtectedRoute exact path="/careplans/:name(report)/:id([0-9]*)" component= { CareplanDetailsPage } />
              <ProtectedRoute exact path="/careplans/:name(task)/:cust_id([0-9]*)/:id([0-9]*)/new/:cust_name" component= { CareplanTaskNewPage } />
              <ProtectedRoute exact path="/careplans/:name(task)/:id([0-9]*)" component= { CareplanTaskPage } />

              <ProtectedRoute exact path="/settings/rostershift/new" component= { RosterShiftNewPage } />
              <ProtectedRoute exact path="/settings/rostershift" component= { RosterShiftPage } />
              <ProtectedRoute exact path="/settings/rostertask" component= { RosterTaskPage } />
              <ProtectedRoute exact path="/settings/rostertask/new" component= { RosterTaskNewPage } />
              
              <ProtectedRoute exact path="/:role(facility|deployment)_caregiver/new" component={ CaregiverNewPage } />
              <ProtectedRoute exact path="/:name(facility_caregiver)/:id([0-9]*)" component={ CaregiverViewPage } />
              <ProtectedRoute exact path="/:role(facility|deployment)_customer" component= { FacilityCustomer } />
              <ProtectedRoute exact path="/call_alert_config" component= { FacilityAlertCallConfig } />
              <ProtectedRoute exact path="/facility_room_config" component= { FacilityRoomConfiguration } />
              <ProtectedRoute exact path="/room_config" component= { RoomConfigurationPage } />
              <ProtectedRoute exact path="/room_config/new" component= { RoomNewPage } />
              <ProtectedRoute exact path="/room_config/:id([0-9]*)" component={ RoomConfigViewPage } />
              <ProtectedRoute exact path="/zone" component= { ZonePage } />
              <ProtectedRoute exact path="/zone/new" component= { ZoneNewPage } />
              <ProtectedRoute exact path="/zone/:id([0-9]*)" component= { ZoneViewPage } />
              
              <ProtectedRoute exact path="/:work_title(facility_work_experience|dashboard_work_experience)/list/:agent_id([0-9]*|kL5iVa7a|Mfpcq69W)" component= { WorkExperienceListPage } />
              <ProtectedRoute exact path="/:work_title(work_experience)/list/:agent_id([0-9]*|kL5iVa7a|Mfpcq69W)" component= { WorkExperienceList2Page } />
              <ProtectedRoute exact path="/work_experience/new/:agent_id([0-9]*|XoTU4zjj)" component= { WorkExperienceNewPage } />
              <ProtectedRoute exact path="/work_experience/:id_type(wid|aid)/:id([0-9]*)" component= { WorkExperienceViewPage } />
              <ProtectedRoute exact path="/salary-listing" component={SalaryListingPage}/>
              <ProtectedRoute exact path="/homecare-dashboard" component={HomecareDashboardPage}/>

              <ProtectedRoute exact path="/digital_diary/list/:customer_id([0-9]*)" component= { DigitalDiaryPage } />
              
              <ProtectedRoute exact path="/facility_dashboard" component={FacilityDashboardPage}/>
              <ProtectedRoute exact path="/ancillary_services/:id([0-9]*)/:name?/:centre([0-9]*)" component={AncillaryServicesPage}/>
              <ProtectedRoute exact path="/ancillary_services_klang_valley/:id([0-9]*)/:name?/:centre([0-9]*)" component={AncillaryServicesPageKlangValley}/>
              <ProtectedRoute exact path="/medical_condition/setting" component={MedicalConditionSettingPage}/>

              <ProtectedRoute excat path="/ancillary_listing" component={AncillaryListingPage}/>
              <ProtectedRoute exact path="/resident_admission/customer_id=:id([a-zA-Z0-9+=%]*)" component={ResidentAdmissionPage}/>
            </React.Suspense>
          </MainLayout>
        </ProfileContext.Provider>
        </AuthContext.Provider>
        </AlertProvider>
      </Switch>
    </BrowserRouter>
  );
}

const query = ({ width }) => {
  if (width < 575) {
    return { breakpoint: 'xs' };
  }
  
  if (576 < width && width < 767) {
    return { breakpoint: 'sm' };
  }

  if (768 < width && width < 991) {
    return { breakpoint: 'md' };
  }

  if (992 < width && width < 1199) {
    return { breakpoint: 'lg' };
  }

  if (width > 1200) {
    return { breakpoint: 'xl' };
  }

  return { breakpoint: 'xs' };
};

export default componentQueries(query)(App);
