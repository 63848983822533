import React, { useState, useEffect } from 'react';
import { useSortBy, useTable, usePagination } from 'react-table';
import Proptypes from 'utils/propTypes';
import {
  Button,
  Table,
  Collapse,
  Input,
  Row,
  Col
} from 'reactstrap';
import {
  MdAdd,
  MdCheckCircle,
  MdCancel,
  MdCheckBoxOutlineBlank,
  MdIndeterminateCheckBox,
  MdCheckBox,
  MdAddCircle,
  MdNotificationsOff,
  MdNotificationsActive
} from 'react-icons/md';
import {
  TiCancel, TiEye
} from 'react-icons/ti'
import {
  BiEdit,
  BiBullseye,
  BiTrash,
  BiPlusCircle,
  BiPlus
} from 'react-icons/bi';
import {
  cgRateCalculatorRaw,
  OrderStatus,
  relationships,
  RoleType,
  searchCentre,
  RelationshipAccessLevelDefinition,
} from "utils/constants";
import { useHistory } from 'react-router-dom';
import { TimesheetStatus, PanicStatus, PaymentStatus, cgRateCalculator } from 'utils/constants';
import * as moment from 'moment';
import { MdArrowDropUp, MdArrowDropDown } from 'react-icons/md' ;
import { AiOutlineDoubleLeft, AiOutlineDoubleRight, AiOutlineArrowLeft, AiOutlineArrowRight } from 'react-icons/ai';
import { useProfileContext } from 'utils/contexts';
import parseHtml from 'html-react-parser';
import TablePaginationWidget from 'components/Widget/TablePaginationWidget';

export const BillingSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  )

  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          console.log({row});
          prepareRow(row)
          return (
            <tr onClick={ () => {
              if(row.original?.InvoiceID === undefined) {
                alert("No invoice id found");
              } else {
                history.push(`/facility_billing/${row.original?.InvoiceID}`);
              }
            } } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>  
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
    </div>
    </>
  );
}

export const CustomerSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
} = useTable(
    { 
        columns, 
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
    }, 
    useSortBy,
    usePagination,
)

const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={ () => history.push(`/customers/${row.values.id}`) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

CustomerSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

CustomerSortableTable.defaultProps = {
  header: [],
  values: []
};

export const CaregiverSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
} = useTable(
    { 
        columns, 
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
    }, 
    useSortBy,
    usePagination,
)

const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={ () => history.push(`/caregivers/${row.values.id}`) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

CaregiverSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

CaregiverSortableTable.defaultProps = {
  header: [],
  values: []
};

export const OrderSortableTable = ({ columns, data, onDataClick, centre }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  )

  const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr style={{backgroundColor: row.values.status == "completed"? "#dbead8" : ""}} onClick={ onDataClick === undefined ? () => {centre == 6? (window.open(`/orders/${row.values.id}`)): (window.open(`/facility_orders/${row.values.id}`))} : () => onDataClick(row) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

OrderSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

export const OrderDetailSortableTable = ({ headers, values, totalPrice, onClickCall, onDelete, orderStatus, onAddNewProduct, ...restProps }) => {
  return (
    <div style={{ overflowX: 'auto' }}>
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({product_name, remarks, product_price, id, isDeleted, quantity }, index) => (
          isDeleted !== 1 &&
          <tr key={index} style={{tableLayout: "fixed"}}>
            {
              orderStatus === OrderStatus.draft && 
              <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"5%" }}>
                <div className='btn btn-xs btn-primary' style={{paddingTop: 2, paddingBottom: 2, background:"transparent", border:"none", color:"black"} }>
                  <BiTrash style={{color: "black"}} onClick = {
                    onDelete != null?
                      onDelete.bind(this, values[index]): null                  
                  }/>
                </div>
              </td>
            }
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"25%" }}>{ product_name }</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"25%" }}>
              <div className = "text-left" style={{"display":"inline-block"}}> 
              {  
                remarks?.includes(null)?  "" : remarks?.includes("</")?
                parseHtml(remarks):
                remarks 
              }
              </div>
            </td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' }}>
              <div className='btn btn-xs btn-primary' style={{paddingTop: 2, paddingBottom: 2, marginLeft: 10, background:"transparent", border:"none", color:"black"} }>
                <BiEdit style={{color: "black"}} onClick = {
                  onClickCall != null?
                    onClickCall.bind(this, values[index]): null                  
                }/>
              </div>
            </td>
            <td className="align-middle text-center">{ (parseFloat(product_price ?? 0)).toLocaleString(undefined, {minimumFractionDigits: 2})}</td>
            <td className="align-middle text-center">{ quantity ?? 1 }</td>
            <td className="align-middle text-center" style={{width:"25%"}}>{ ((parseFloat(product_price ?? 0) * parseFloat(quantity ?? 1))).toLocaleString(undefined, {minimumFractionDigits: 2})}</td>
          </tr>
        ))}
        {
          orderStatus === OrderStatus.draft &&
          <tr>
            <td className="text-center font-weight-bold"></td>
            <td className="font-weight-bold" style={{textAlign:"center"}}>
              {
                <Button outline color='primary'>
                  <BiPlus style={{color: 'primary'}} onClick = {onAddNewProduct}/>
                </Button>
              }
            </td>
            <td className="text-center font-weight-bold" colSpan={5}></td>
          </tr>
        }
        <tr>
          <td className="text-right font-weight-bold" colSpan={orderStatus === OrderStatus.draft ? 6 : 5}>Total Without Taxes(RM): </td>
          <td className="text-center font-weight-bold">{ totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2}) }</td>
        </tr>
        <tr>
          <td className="text-right font-weight-bold" colSpan={orderStatus === OrderStatus.draft ? 6 : 5}>Taxes(RM): </td>
          <td className="text-center font-weight-bold">0.00</td>
        </tr>
        <tr>
          <td className="text-right font-weight-bold" colSpan={orderStatus === OrderStatus.draft ? 6 : 5}>Total(RM): </td>
          <td className="text-center font-weight-bold">{ totalPrice.toLocaleString(undefined, {minimumFractionDigits: 2}) }</td>
        </tr>
      </tbody>
    </Table>
    </div>
  );
}

OrderDetailSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

export const ProductSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  )

  const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr 
              onClick={ () => history.push(`/products/${row.values.id}`)} {...row.getRowProps()}
              style={{
                backgroundColor: row?.values?.status === "inactive" ? "#ff9785" : ""
              }}
            >
              {
              row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

ProductSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

ProductSortableTable.defaultProps = {
  header: [],
  values: []
};

export const JobSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  )

  const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table table-hover">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={ () => window.open(`/jobs/${row?.values?.id}`) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

JobSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

JobSortableTable.defaultProps = {
  header: [],
  values: []
};

export const JobInterestSortableTable = ({ headers, values, ...restProps }) => {
  const history = useHistory();
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {
          values.length === 0 && 
          <tr>
            <td colSpan="3" className="align-middle text-center">No Candidate</td>
          </tr>
        }
        {values.map(({ id, caregiver_id, first_name, last_name, mobile, created_at, from }, index) => (
          <tr key={index} onClick={ () => history.push(`/caregivers/${caregiver_id}`) }>
            <td className="align-middle text-center">{ id }</td>
            <td className="align-middle text-center">{ `${first_name} ${last_name}` }</td>
            <td className="align-middle text-center">{ mobile }</td>
            <td className="align-middle text-center">{ moment(created_at).utc().format("YYYY-MM-DD HH:mm:ss") }</td>
            <td className="align-middle text-center">{ from ?? "No data" }</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

JobInterestSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

JobInterestSortableTable.defaultProps = {
  header: [],
  values: []
};

export const TimesheetSortableTable = ({ 
  columns, 
  data,
  selectedTimesheetDetails,
  onSetSelectedTimesheetDetails, 
  index
}) => {
    
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  );
  const pageSizeArray = [20, 50, 100];

  return (
    <div style={{ overflowX: 'auto' }}>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          const data = row.original;
          return (
            <tr  {...row.getRowProps()} 
            className={data.status == TimesheetStatus.rejected ? "bg-rejected" : data.status == TimesheetStatus.approved ? "bg-approved": ""}
            style={{backgroundColor: selectedTimesheetDetails == null? undefined:(selectedTimesheetDetails.some((x) => x.id === data.id) ? 'lightblue' : undefined)}} 
            onClick={() => {
              if(data.status !== TimesheetStatus.open)
                return 
              if(selectedTimesheetDetails != null){
                const cloneSelectedSalary = {id: data.id, date: data.date_work, cg: `${data.cg_first_name} ${data.cg_last_name}`, timesheet: data};
                onSetSelectedTimesheetDetails(cloneSelectedSalary, index)
              }
            }}
            >
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </div>
  );
}

TimesheetSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

TimesheetSortableTable.defaultProps = {
  header: [],
  values: [],
  isAppointmentView: false,
  onAddTimesheet: null
};

export const BadgesSortableTable = ({ headers, values, ...restProps }) => {
  const history = useHistory();
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-left text-left">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({ id, category_id, code, badge, description }, index) => (
          <tr key={index} onClick={ () => history.push(`/badges/${id}`) }>
            <td className="align-left text-left">{ id }</td>
            <td className="align-left text-left">{ category_id }</td>
            <td className="align-left text-left">{ code }</td>
            <td className="align-left text-left">{ badge }</td>
            <td className="align-left text-left">{ description }</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

BadgesSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

BadgesSortableTable.defaultProps = {
  header: [],
  values: []
};

export const BadgesCatSortableTable = ({ headers, values, ...restProps }) => {
  const history = useHistory();
  
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-left text-left">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({ id, name, created_at, created_by }, index) => (
          <tr key={index} onClick={ () => history.push(`/badges_category/${id}`) }>
            <td className="align-left text-left">{ id }</td>
            <td className="align-left text-left">{ name }</td>
            <td className="align-left text-left">{ moment(created_at).utc().format("DD/MM/YYYY") }</td>
            <td className="align-left text-left">{ created_by }</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

BadgesCatSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

BadgesCatSortableTable.defaultProps = {
  header: [],
  values: []
};

export const AppointmentsSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  )

  const pageSizeArray = [20, 50, 100];

  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={ () => history.push(`/appointments/${row.values.id}`) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

AppointmentsSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

AppointmentsSortableTable.defaultProps = {
  header: [],
  values: []
};


export const SalarySortableTable = ({ headers, values, totalAmount, selectedTimesheet, onSetSelectedTimesheet, onClickCall, onPdfClick, ...restProps }) => {  
  const [sortConfig, setSortConfig] = useState(null);

  const sortedValues = React.useMemo(() => {
    let sortableItems = [...values];
    if (sortConfig !== null) {
      
      switch(sortConfig.key) {
        case "total":
          sortableItems.sort((a, b) => {
            const aValue = cgRateCalculatorRaw(a.cg_rate, a.product_type, a.start_time, a.end_time) + (a.allowance ?? 0) + (a.transport ?? 0);
            const bValue = cgRateCalculatorRaw(b.cg_rate, b.product_type, b.start_time, b.end_time) + (b.allowance ?? 0) + (b.transport ?? 0);
            if (aValue < bValue) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
          break;
        case "salary":
          sortableItems.sort((a, b) => {
            const aValue = cgRateCalculatorRaw(a.cg_rate, a.product_type, a.start_time, a.end_time);
            const bValue = cgRateCalculatorRaw(b.cg_rate, b.product_type, b.start_time, b.end_time);
            if (aValue < bValue) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (aValue > bValue) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
          break;
        default:     
          sortableItems.sort((a, b) => {
            if (a[sortConfig.key] < b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? -1 : 1;
            }
            if (a[sortConfig.key] > b[sortConfig.key]) {
              return sortConfig.direction === 'ascending' ? 1 : -1;
            }
            return 0;
          });
      }
    }
    return sortableItems;
  }, [values, sortConfig]);

  const requestSort = (key) => {
    let direction = 'ascending';
    if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  }

  console.log(values);
  const profile = JSON.parse(window.localStorage.getItem('profile'));
  return (
    <div style={{ overflowX: 'auto' }}>
    <Table responsive="true" sortable="false" hover { ...restProps } style={{width:'100%'}}>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {/* <th>NO</th> */}
          <th onClick={() => {

            if(values.some(item => item.payment === PaymentStatus.processed)) {
              return alert('Salary timesheet contain processed list');
            }

            let selectAllSalaryTimesheet = []
            
            if(selectedTimesheet.length === 0) {
              selectAllSalaryTimesheet = values.filter((item) => item.status === TimesheetStatus.approved).map(item => {
                if(item.batch == null && item.flag == null){
                  return ({
                    id: item.id,
                    cg_rate: item.cg_rate ?? 0.00,
                    date_work: item.date_work,
                    allowance: item.allowance,
                    status: item.status,
                    ref_no: item.ref_no,
                    invoice_ref: item.invoice_ref,
                    payment: item.payment,
                    bank_name: item.bank_name,
                    bank_cardholder: item.bank_cardholder,
                    bank_account: item.bank_account,
                    xero_invoice_status: item.xero_invoice_status,
                    xero_invoice_id: item.xero_invoice_id,
                    a_remarks: item.a_remarks,
                    centre: item.centre,
                    product_name: item.product_name,
                    product_code: item.product_code,
                    flag: item.flag,
                    batch: item.batch,
                    transport: item.transport,
                  });
                } else {
                  return {};
                }
              });
            }
            onSetSelectedTimesheet(selectAllSalaryTimesheet, selectAllSalaryTimesheet); 
          }}>
            {selectedTimesheet.length === 0 ? <MdCheckBoxOutlineBlank size={25}/> : <MdCheckBox size={25}/>} 
          </th>
          {headers.map((item, index) => <th key={index} style={{cursor: 'pointer'}} onClick={() => {requestSort(item.key)}}>
            {item.name}  
              <span>
                {sortConfig?.key === item?.key
                  ? sortConfig?.direction === 'descending'
                    ? <MdArrowDropDown size={24} color="#1C9EEF" />
                    : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                  : ''}
              </span>
            </th>)}
        </tr>
      </thead>
      <tbody>
        {sortedValues.map(({ id, date_work, allowance, status, ref_no, invoice_ref,payment, bank_name, bank_cardholder, bank_account, cg_rate, xero_invoice_status, xero_invoice_id, a_remarks, centre, product_name, product_code, flag, batch, product_type, start_time, end_time, transport}, index) => (
          status === TimesheetStatus.approved &&
          <tr
            className="text-center"
            key={index}
            style={{width: '100%', tableLayout: "fixed", backgroundColor: selectedTimesheet.some((salaryItem) => salaryItem.id === id) ? 'lightblue' : undefined}}
            onClick={() => {
              if(payment !== PaymentStatus.processed && flag !== 1){
                const singleData = {
                  id: id,
                  cg_rate: cg_rate ?? 0.00,
                  date_work: date_work,
                  allowance: allowance,
                  status: status,
                  ref_no: ref_no,
                  invoice_ref: invoice_ref,
                  payment: payment,
                  bank_name: bank_name,
                  bank_cardholder: bank_cardholder,
                  bank_account: bank_account,
                  xero_invoice_status: xero_invoice_status,
                  xero_invoice_id: xero_invoice_id,
                  a_remarks: a_remarks,
                  centre: centre,
                  product_name: product_name,
                  product_code: product_code, 
                  flag: flag,
                  batch: batch,
                  transport: transport,
                };
                console.log(singleData)
                const cloneSelectedSalary = 
                  selectedTimesheet.some((salaryItem) => salaryItem.id === id) ?
                  selectedTimesheet.filter((item) => item.id !== id) :
                  [...selectedTimesheet, singleData];
                onSetSelectedTimesheet(cloneSelectedSalary, [singleData]);
              }
            }}>
            {/* <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' }}> { index + 1 } </td> */}
            <td style={{'whiteSpace': 'unset', width:'5%'}}>
              {
                payment === PaymentStatus.processed || payment != null || flag === 1?<MdIndeterminateCheckBox size={25} color={'lightgrey'}/>:(
                  selectedTimesheet.some((salaryItem) => salaryItem.id === id) ? 
                  <MdCheckBox size={25} /> : 
                  <MdCheckBoxOutlineBlank size={25}/>
                )
              } 
            </td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , maxWidth:"90px" }}> { date_work } </td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"5%" }}>{ (cgRateCalculatorRaw(cg_rate, product_type, start_time, end_time) ?? 0).toFixed(2)}</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"10%" }}>{ transport ? parseFloat(transport)?.toFixed(2) : "0.00" }</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"5%" }}>{ allowance ? parseFloat(allowance)?.toFixed(2) : "0.00" } </td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"5%" }}>{ parseFloat((cgRateCalculatorRaw(cg_rate, product_type, start_time, end_time) ?? 0) + allowance + (transport ?? 0))?.toFixed(2) }</td>
            <td className="align-middle text-center" 
              style={{ 
                'whiteSpace': 'unset',
                maxWidth:"100px", 
                color: (xero_invoice_id && (xero_invoice_status === "PAID" || xero_invoice_status === "AUTHORISED" || payment === "PAID" || payment === "AUTHORISED")) ? "blue" : "", 
                textDecoration: (xero_invoice_id && (xero_invoice_status === "PAID" || xero_invoice_status === "AUTHORISED" || payment === "PAID" || payment === "AUTHORISED")) ? "underline" : "",
                cursor: (xero_invoice_id && (xero_invoice_status === "PAID" || xero_invoice_status === "AUTHORISED" || payment === "PAID" || payment === "AUTHORISED")) ? "pointer" : ""
              }}
              onClick={() => {
                if (xero_invoice_status === "PAID" || xero_invoice_status === "AUTHORISED" || payment === "PAID" || payment === "AUTHORISED") {
                  onPdfClick(xero_invoice_id, centre);
                }
              }}
            >{ invoice_ref ?? "-"}</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , maxWidth: '130px', color: xero_invoice_status === "DRAFT" ? "red" : "green"}}>{ xero_invoice_status === "DRAFT" ? "PENDING APPROVAL" : xero_invoice_status ?? payment ?? "-"}</td>
            {/* <td className="align-middle text-center">{ payment ?? "-" }</td> */}
            <td className="align-middle text-centre" style={{'whiteSpace': 'unset', maxWidth: '150px', overflow: 'hidden', textOverflow: 'ellipsis'}}>{ a_remarks }</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"120px" }}> { batch } </td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"5%" }}>
              {
                (payment == null || profile.type == RoleType.finance || profile.type == RoleType.superadmin) && !batch ?
                <BiEdit onClick = {
                  onClickCall != null?
                    onClickCall.bind(this, values[index]): null                  
                }/> : 
                <></>
              }
            </td>
          </tr>
        ))}
        <tr>
          <td className="text-left font-weight-bold" colSpan={5}>Total PAID: </td>
          <td className="text-center font-weight-bold">{
            values.reduce((acc, ts) => {
              if (ts.xero_invoice_status === "PAID" && ts.status === TimesheetStatus.approved)
                return acc + ((cgRateCalculator(ts)) ?? 0) + (ts.allowance ?? 0) - (ts.transport ?? 0);
              return acc;
            }, 0).toFixed(2)
          }</td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td className="text-left font-weight-bold" colSpan={5}>Total UNPAID: </td>
          <td className="text-center font-weight-bold">{
            values.reduce((acc, ts) => {
              if (ts.xero_invoice_status !== "PAID" && ts.status === TimesheetStatus.approved)
                return acc + ((cgRateCalculator(ts)) ?? 0.00) + ((ts.allowance) ?? 0.00) + (ts.transport ?? 0);
              return acc;
            }, 0).toFixed(2)
          }</td>
          <td colSpan={5}></td>
        </tr>
        <tr>
          <td className="text-left font-weight-bold" colSpan={5}>Total Amount: </td>
          <td className="text-center font-weight-bold">{totalAmount}</td>
          <td colSpan={5}></td>
        </tr>
      </tbody>
    </Table>
    </div>
  );
}


export const PanicSortableTable = ({ headers, values, onClick, ...restProps }) => {
  const [triggers, setTriggers] = useState(values);

  useEffect(() => {
    setTriggers(values)
  }, [values])

  useEffect(() => {
    const elapsed = setInterval(() => {
      let newTriggers = triggers.map((v) => {
        v["elapsed_time"] = moment(new Date()).diff(moment(v.date_triggered), 'seconds');
        return v;
      });

      setTriggers(newTriggers)
    }, 1000);

    return () => clearInterval(elapsed);
  }, [triggers]);

  const calculateElapsed = (seconds) => {
    return Math.floor(seconds / 60) + ":" + (seconds % 60 ? seconds % 60 : '00')
  }

  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {triggers.map(({ id, customer_name, zone, location, date_triggered, elapsed_time, status, nurse_name }, index) => (
          <tr key={index}>
          <td className="align-middle text-center">{ id }</td>
            <td className="align-middle text-center">{ customer_name }</td>
            <td className="align-middle text-center">{ zone }</td>
            <td className="align-middle text-center">{ location }</td>
            <td className="align-middle text-center">{ date_triggered }</td>
            <td className="align-middle text-center" style={{color: status == "Handling" ? "#14a620" : "inherit"}}>{ status }</td>
            <td className="align-middle text-center">{ nurse_name ?? "-" }</td>
            <td className="align-middle text-center">{ calculateElapsed(elapsed_time ?? 0) }</td>
            <td className="align-middle text-center">
              <span style={{ cursor: "pointer" }}><MdCheckCircle onClick={ () => onClick(PanicStatus.resolved, id) } color="green" size={ 24 } /></span> 
              <span style={{ cursor: "pointer" }}><TiCancel onClick={ () => onClick(PanicStatus.cancelled, id) } color="red" size={ 28 } /></span>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

PanicSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

PanicSortableTable.defaultProps = {
  header: [],
  values: []
};

export const OrderStatusAuditSortable = ({ columns, data }) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  )

  const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

OrderStatusAuditSortable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

OrderStatusAuditSortable.defaultProps = {
  header: [],
  values: []
}

SalarySortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node,
  rate: Proptypes.node
}

SalarySortableTable.defaultProps = {
  header: [],
  values: [],
  rate: 0
}

export const MaintenanceSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
} = useTable(
    { 
        columns, 
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
    }, 
    useSortBy,
    usePagination,
)

const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={ () => history.push(`/facility_maintenance/${row.values.id}`) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

MaintenanceSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

MaintenanceSortableTable.defaultProps = {
  header: [],
  values: []
};

export const CareplanSortableTable = ({ headers, values, custId,...restProps }) => {
  const history = useHistory();
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({ id, title, location, end_date, start_date, name, project_type }, index) => (
          <tr key={index + "-report-table-" + id } onClick={ () => history.push(`/careplans/${custId}/view/${id}`) }>
            <td className="align-middle text-center">{ id }</td>
            <td className="align-middle text-center">{ title }</td>
            <td className="align-middle text-center">{ location }</td>
            <td className="align-middle text-center">{ `${moment(start_date).format("DD MM YYYY")}-${moment(end_date).format("DD MM YYYY")}` }</td>
            <td className="align-middle text-center">{ name }</td>
            <td className="align-middle text-center">{ project_type }</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

CareplanSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

CareplanSortableTable.defaultProps = {
  header: [],
  values: []
};



export const CareplanDetailsSortableTable = ({ headers, values, name = "details", type,...restProps }) => {
  const history = useHistory();
  if(values.length > 0){
    return (
      <Table responsive="true" hover { ...restProps }>
        <thead>
          <tr className="text-capitalize align-middle text-center">
            {headers.map((item, index) => <th key={index + item}>{item}</th>)}
          </tr>
        </thead>
        <tbody>
          {values.map(({ report_id, title, datetime, notes, location_name }, index) => (
            <tr key={"report-table-"+report_id} onClick={ () => history.push(`/careplans/${name}/${report_id}`) }>
              <td className="">
                <h4><b>{ title }</b></h4>
                <div>
                  Notes: {notes}
                </div>
                <div className="row">
                  <div className="col">
                    Location : <b>{location_name}</b>
                  </div>
                  <div className="col text-right">
                    {moment(datetime).format("YYYY-MM-DD")}
                  </div>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );
  }else{
      return (
        <center>  
          <div margin="10">
            <h3>No record</h3>
          </div>
        </center>
    );
  }
}

CareplanDetailsSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

CareplanDetailsSortableTable.defaultProps = {
  header: [],
  values: []
};


export const CareplanTaskSortableTable = ({ headers, values, name, ...restProps }) => {
  const history = useHistory();
  if(values.length <= 0){
    return (
      <center>  
        <div margin="10">
          <h3>No record</h3>
        </div>
      </center>
  );
}
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index + item}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
          {values.map(({ task_id, task, title, start_date, end_date, notes, time }, index) => (
            <tr key={'task'+task_id} onClick={ () => history.push(`/careplans/${name}/${task_id}`) }>
            <td className="">
              <h4><b>{ title }</b> ({task}) </h4>
              <div>
                Notes: {notes}
              </div>
              <div className="row">
                <div className="col">
                  Location : <b>{moment(time).format("hh:mm a")}</b>
                </div>
                <div className="col text-right">
                  {moment(start_date).format("YYYY-MM-DD")} - {moment(end_date).format("YYYY-MM-DD")}
                </div>
              </div>
            </td>
          </tr>
          ))}
      </tbody>
    </Table>
  );
}

CareplanTaskSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

CareplanTaskSortableTable.defaultProps = {
  header: [],
  values: []
};

export const RosterShiftSortableTable = ({ headers, values, ...restProps }) => {
  const history = useHistory();
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({ id, name, color, centre_id }, index) => (
           <tr key={index}  /*onClick={ () => history.push(`/roster_shift/${id}`) }*/>
            <td className="align-middle text-center">{ id }</td>
            <td className="align-middle text-center">{ name }</td>
            <td className="align-middle text-center">{ color }</td>
            <td className="align-middle text-center">{ centre_id }</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

RosterShiftSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

RosterShiftSortableTable.defaultProps = {
  header: [],
  values: []
};

export const RosterTaskSortableTable = ({ headers, values, ...restProps }) => {
  const history = useHistory();
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({ id, name, centre_id }, index) => (
           <tr key={index}  /*onClick={ () => history.push(`/roster_task/${id}`) }*/>
            <td className="align-middle text-center">{ id }</td>
            <td className="align-middle text-center">{ name }</td>
            <td className="align-middle text-center">{ centre_id }</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

RosterTaskSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

RosterTaskSortableTable.defaultProps = {
  header: [],
  values: []
};

export const WorkExperienceSortableTable = ({ headers, values, ...restProps }) => {
  const history = useHistory();
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({ id, agent_name, work_position, work_company, verified, verified_at, verified_name }, index) => (
           <tr key={index} onClick={ () => history.push(`/work_experience/wid/${id}`) }>
            <td className="align-middle text-center">{ id }</td>
            <td className="align-middle text-center">{ agent_name }</td>
            <td className="align-middle text-center">{ work_position }</td>
            <td className="align-middle text-center">{ work_company }</td>
            <td className="align-middle text-center">{ verified === 1 ? "Verified" : "Not Verified" }</td>
            <td className="align-middle text-center">{ verified_at? moment(verified_at).format("YYYY-MM-DD hh:mm a"): "-" }</td>
            <td className="align-middle text-center">{ verified_name? verified_name : "-" }</td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
}

WorkExperienceSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

WorkExperienceSortableTable.defaultProps = {
  header: [],
  values: []
};


export const FacilityOrderSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
  } = useTable(
      { 
          columns, 
          data,
          initialState: { pageIndex: 0, pageSize: 20 },
      }, 
      useSortBy,
      usePagination,
  )

  const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row);
          return (
            <tr onClick={ () => window.open(`/facility_orders/${row.values.id}`) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

FacilityOrderSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

export const FacilityOrderDetailSortableTable = ({ headers, values, totalPrice, onClickCall, ...restProps }) => {
  return (
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({product_name, remarks, product_price }, index) => (
          <tr key={index}>
            <td className="align-middle text-center">{ product_name }</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' }}>
              { remarks }
              <div className='btn btn-xs btn-primary' style={{paddingTop: 2, paddingBottom: 2, marginLeft: 10, background:"transparent", border:"none"}}>
                <BiEdit style={{color: "black"}} onClick = {
                  onClickCall != null?
                    onClickCall.bind(this, values[index]): null                  
                }/>
              </div>
            </td>
            <td className="align-middle text-center">{ product_price.toFixed(2) }</td>
          </tr>
        ))}
        <tr>
          <td className="text-right font-weight-bold" colSpan={2}>Total Without Taxes: </td>
          <td className="text-center font-weight-bold">{ totalPrice.toFixed(2) }</td>
        </tr>
        <tr>
          <td className="text-right font-weight-bold" colSpan={2}>Taxes: </td>
          <td className="text-center font-weight-bold">0.00</td>
        </tr>
        <tr>
          <td className="text-right font-weight-bold" colSpan={2}>Total: </td>
          <td className="text-center font-weight-bold">{ totalPrice.toFixed(2) }</td>
        </tr>
      </tbody>
    </Table>
  );
}

FacilityOrderDetailSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

export const RoomConfigSortableTable = ({ columns, data, profileType }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
} = useTable(
    { 
        columns, 
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
    }, 
    useSortBy,
    usePagination,
)

const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td onClick={ () => profileType == "superadmin" && cell.column.Header !== "Customer" && history.push(`/room_config/${row.values.id}`) }
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

RoomConfigSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

RoomConfigSortableTable.defaultProps = {
  header: [],
  values: []
};

export const ZoneListSortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
} = useTable(
    { 
        columns, 
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
    }, 
    useSortBy,
    usePagination,
)

const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={ () => history.push(`/zone/${row.values.id}`) } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

ZoneListSortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

ZoneListSortableTable.defaultProps = {
  header: [],
  values: []
};


export const AncillarySecondarySortableTable = ({ columns, data }) => {
  const history = useHistory();
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize },
} = useTable(
    { 
        columns, 
        data,
        initialState: { pageIndex: 0, pageSize: 20 },
    }, 
    useSortBy,
    usePagination,
)

const pageSizeArray = [20, 50, 100];
  return (
    <>
    <table {...getTableProps()} className="table">
      <thead>
        {headerGroups.map(headerGroup => (
          <tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map(column => (
              <th className={column.className}
                {...column.getHeaderProps(column.getSortByToggleProps())}
              >
                {column.render('Header')}
                <span>
                    {column.isSorted
                      ? column.isSortedDesc
                        ? <MdArrowDropDown size={24} color="#1C9EEF" />
                        : <MdArrowDropUp size={24}  color="#1C9EEF"/>
                      : ''}
                  </span>
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody {...getTableBodyProps()}>
        {page.map((row, i) => {
          prepareRow(row)
          return (
            <tr onClick={ () => {} } {...row.getRowProps()}>
              {row.cells.map(cell => {
                return (
                  <td 
                    {...cell.getCellProps({className: cell.column.className})}
                  >
                      
                    {cell.render('Cell')}
                  </td>
                )
              })}
            </tr>
          )
        })}
      </tbody>
    </table>
    <div>
      <TablePaginationWidget
        gotoPage={gotoPage}
        canPreviousPage={canPreviousPage}
        previousPage={previousPage}
        canNextPage={canNextPage}
        nextPage={nextPage}
        pageIndex={pageIndex}
        pageOptions={pageOptions}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageCount={pageCount}
      />
      </div>
    </>
  );
}

AncillarySecondarySortableTable.prototype = {
  header: Proptypes.node,
  values: Proptypes.node
}

AncillarySecondarySortableTable.defaultProps = {
  header: [],
  values: []
};


/**
 * Renders a sortable table component for displaying relationship data.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.headers - The array of table headers.
 * @param {Array} props.values - The array of table values.
 * @param {Function} props.editCareFamily - The function to handle editing a care family.
 * @param {Function} props.deleteCareFamily - The function to handle deleting a care family.
 * @param {Object} restProps - The rest of the props passed to the component.
 * @returns {JSX.Element} The rendered sortable table component.
 */
export const RelationShipSortableTable = ({ headers, values, editCareFamily, deleteCareFamily, editSubscriptions, name: upperName,...restProps }) => {
  return (
    <div style={{ overflowX: 'auto' }}>
    <Table responsive="true" hover { ...restProps }>
      <thead>
        <tr className="text-capitalize align-middle text-center">
          {headers.map((item, index) => <th key={index}>{item}</th>)}
        </tr>
      </thead>
      <tbody>
        {values.map(({id, name, email, priority, linking_subscription }, index) => (
          <tr key={index} style={{tableLayout: "fixed"}}>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"25%" }}>{ name }</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset' , width:"30%" }}>{ email }</td>
            <td className="align-middle text-center">{RelationshipAccessLevelDefinition[priority]?.text ?? ""} ({ priority })</td>
            <td className="align-middle text-center" style={{ 'whiteSpace': 'unset', width: "180px" }}>
              <div 
                className='btn btn-xs btn-primary' 
                style={{paddingTop: 2, paddingBottom: 2, marginLeft: 10, background:"transparent", border:"none", color:"black"}}
                onClick={() => editCareFamily({...values[index], ...{upperName: upperName}})}
              >
                <BiEdit style={{color: "black"}}/>
              </div>
              <div 
                className='btn btn-xs btn-primary' 
                style={{paddingTop: 2, paddingBottom: 2, background:"transparent", border:"none", color:"black"}}
                onClick={() => deleteCareFamily(values[index])}
              >
                <BiTrash style={{color: "black"}}/>
              </div>
              <div 
                className='btn btn-xs btn-primary' 
                style={{paddingTop: 2, paddingBottom: 2, background:"transparent", border:"none", color:"black"}}
                onClick={() => editSubscriptions(values[index])}
              >
                {linking_subscription === 1 ? <MdNotificationsActive style={{color: "green"}}/>: <MdNotificationsOff style={{color: "black"}}/>}
              </div>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    </div>
  );
}