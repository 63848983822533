import React from 'react';
import { Row, Col, Input } from 'reactstrap';
import {
  AiOutlineDoubleLeft,
  AiOutlineArrowLeft,
  AiOutlineArrowRight,
  AiOutlineDoubleRight,
} from 'react-icons/ai';

/**
 * TablePaginationWidget component displays pagination controls for a table.
 *
 * @param {Object} props - The component props.
 * @param {Function} props.gotoPage - Function to go to a specific page.
 * @param {boolean} props.canPreviousPage - Indicates if previous page is available.
 * @param {Function} props.previousPage - Function to go to the previous page.
 * @param {boolean} props.canNextPage - Indicates if next page is available.
 * @param {Function} props.nextPage - Function to go to the next page.
 * @param {number} props.pageIndex - The current page index.
 * @param {Array} props.pageOptions - The available page options.
 * @param {number} props.pageSize - The current page size.
 * @param {Function} props.setPageSize - Function to set the page size.
 * @param {number} props.pageCount - The total number of pages.
 * @returns {JSX.Element} - The rendered TablePaginationWidget component.
 */
const TablePaginationWidget = ({
  gotoPage,
  canPreviousPage,
  previousPage,
  canNextPage,
  nextPage,
  pageIndex,
  pageOptions,
  pageSize,
  setPageSize,
  pageCount,
}) => {
  const pageSizeArray = [20, 50, 100];

  return (
    <Row className="text-right">
      <Col>
        <button
          className="btn btn-outline-primary"
          onClick={() => gotoPage(0)}
          disabled={!canPreviousPage}
        >
          <AiOutlineDoubleLeft />
        </button>{' '}
        <button
          className="btn btn-outline-primary"
          onClick={() => previousPage()}
          disabled={!canPreviousPage}
        >
          <AiOutlineArrowLeft />
        </button>{' '}
        <button
          className="btn btn-outline-primary"
          onClick={() => nextPage()}
          disabled={!canNextPage}
        >
          <AiOutlineArrowRight />
        </button>{' '}
        <button 
          className="btn btn-outline-primary"
          onClick={() => gotoPage((pageCount) - 1)}
          disabled={!canNextPage}
        >
          <AiOutlineDoubleRight/>
        </button>{' '}
        Page {pageIndex + 1} of {pageOptions.length} | Go to page:{' '}
      </Col>
      <Row>
        <Col md={3}>
          <Input
            type="number"
            defaultValue={pageIndex + 1}
            onChange={(e) => {
              const page = e.target.value ? Number(e.target.value) - 1 : 0;
              gotoPage(page);
            }}
            style={{ width: '80px' }}
          />
        </Col>
        <Col md={3}></Col>
        <Col md={6}>
          <Input
            type="select"
            className="rounded-0"
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
          >
            {pageSizeArray.map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {pageSize}
              </option>
            ))}
          </Input>
        </Col>
      </Row>
    </Row>
  );
};

export default TablePaginationWidget;
