export default {
  // MAX_ATTACHMENT_SIZE: 5000000,

  // s3: {
  //   REGION: "ap-southeast-1",
  //   BUCKET: "carealu-customer-images"
  // },
  apiGateway: {
    REGION: "ap-southeast-1",
    URL: process.env.REACT_APP_API_URL,
    URL_TASK: process.env.REACT_APP_TASK_API_URL,
    URL_UPLOAD: process.env.REACT_APP_UPLOAD_URL,
    URL_XERO: process.env.REACT_APP_XERO_API_URL,
    URL_SNS: process.env.REACT_APP_SNS_URL,
    API_KEY: process.env.REACT_APP_API_KEY,
    API_KEY_TASK: process.env.REACT_APP_TASK_API_KEY,
    API_KEY_XERO: process.env.REACT_APP_XERO_API_KEY,
    API_KEY_UPLOAD: process.env.REACT_APP_UPLOAD_API_KEY,
  },
  cognito: {
    REGION: "ap-southeast-1",
    USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
    APP_CLIENT_ID: process.env.REACT_APP_APP_CLIENT_ID,
    IDENTITY_POOL_ID: process.env.REACT_APP_IDENTITY_POOL_ID
  }
};
