import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { Amplify, Auth } from 'aws-amplify';
import config from 'config';
import reportWebVitals from './reportWebVitals';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: config.cognito.REGION,
    userPoolId: config.cognito.USER_POOL_ID,
    identityPoolId: config.cognito.IDENTITY_POOL_ID,
    userPoolWebClientId: config.cognito.APP_CLIENT_ID
  },
  API: {
    endpoints: [
      {
        name: "careadmin",
        endpoint: config.apiGateway.URL,
        region: config.apiGateway.REGION,
        
        custom_header: async () => { 
          //return { 'x-api-key' : '1LQzA1EMIU1WmIv8TDMWV5kv8JE60DpD5C8g3S7a' } 
          // Alternatively, with Cognito User Pools use this:
          // const auth = await Auth.currentSession();
          return {
            // 'Authorization': auth.idToken.jwtToken, 
            'x-api-key' : config.apiGateway.API_KEY 
          }
        }
      },
      {
        name: "task",
        endpoint: config.apiGateway.URL_TASK,
        region: config.apiGateway.REGION,
        
        custom_header: async () => { 
          //return { 'x-api-key' : '1LQzA1EMIU1WmIv8TDMWV5kv8JE60DpD5C8g3S7a' } 
          // Alternatively, with Cognito User Pools use this:
          // const auth = await Auth.currentSession();
          return {
            // 'Authorization': auth.idToken.jwtToken, 
            'x-api-key' : config.apiGateway.API_KEY_TASK 
          }
        }
      },
      {
        name: "xero",
        endpoint: config.apiGateway.URL_XERO,
        region: config.apiGateway.REGION,
        
        custom_header: async () => { 
          //return { 'x-api-key' : '1LQzA1EMIU1WmIv8TDMWV5kv8JE60DpD5C8g3S7a' } 
          // Alternatively, with Cognito User Pools use this:
          // const auth = await Auth.currentSession();
          return {
            // 'Authorization': auth.idToken.jwtToken, 
            'x-api-key' : config.apiGateway.API_KEY_XERO 
          }
        }
      },
      {
        name: "upload",
        endpoint: config.apiGateway.URL_UPLOAD,
        region: config.apiGateway.REGION,
        
        custom_header: async () => { 
          //return { 'x-api-key' : '1LQzA1EMIU1WmIv8TDMWV5kv8JE60DpD5C8g3S7a' } 
          // Alternatively, with Cognito User Pools use this:
          // const auth = await Auth.currentSession();
          return {
            // 'Authorization': auth.idToken.jwtToken, 
            'x-api-key' : config.apiGateway.API_KEY_UPLOAD
          }
        }
      },
    ]
  }
});

ReactDOM.render(<App />, document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
