import React from 'react';
import PropTypes from 'utils/propTypes';

import bn from 'utils/bemnames';
import './../App.css'

import { Breadcrumb, BreadcrumbItem } from 'reactstrap';

import Typography from './Typography';

const bem = bn.create('page');

const Page = ({
  title,
  titleLeading,
  breadcrumbsParent,
  breadcrumbs,
  tag: Tag,
  className,
  children,
  ...restProps
}) => {
  const classes = bem.b('px-3', className);
  const [sidebarWidth, setSidebarWidth] = React.useState(undefined);
  const [sidebarTop, setSidebarTop] = React.useState(undefined);
   
  React.useEffect(() => {
    const sidebarEl = document.querySelector('.sticky-bar-title').getBoundingClientRect();
    if(sidebarEl != null){
      setSidebarWidth(sidebarEl.width);
      setSidebarTop(sidebarEl.top);
    }
  }, []);

  
  React.useEffect(() => {
  if (!sidebarTop) return;
 
  window.addEventListener('scroll', isSticky);
  return () => {
    window.removeEventListener('scroll', isSticky);
  };
}, [sidebarTop]);
 
const isSticky = (e) => {
  const sidebarEl = document.querySelector('.sticky-bar-title');
  const scrollTop = window.scrollY;
  if(sidebarEl?.classList !== null){
    if (scrollTop >= sidebarTop) {
      sidebarEl?.classList.add('is-sticky');
    } else {
      sidebarEl?.classList.remove('is-sticky');
    }
  }
}

  return (
    <Tag className={classes} {...restProps}>
      <div className='row sticky-bar-title' style={{backgroundColor:"#fcfcfa"}}>
        <div className={bem.e('header') + ' col'}>
          {title && typeof title === 'string' ? (
            <Typography type="h1" className={bem.e('title')}>
              {title}
            </Typography>
          ) : (
              title
            )}
          {breadcrumbs && (
            <Breadcrumb className={bem.e('breadcrumb')}>
              <BreadcrumbItem>{breadcrumbsParent}</BreadcrumbItem>
              {breadcrumbs.length &&
                breadcrumbs.map(({ name, active }, index) => (
                  <BreadcrumbItem key={index} active={active}>
                    {name}
                  </BreadcrumbItem>
                ))}
            </Breadcrumb>
          )}
        </div>
      </div>
      {children}
    </Tag>
  );
};

Page.propTypes = {
  tag: PropTypes.component,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
  className: PropTypes.string,
  children: PropTypes.node,
  breadcrumbs: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
};

Page.defaultProps = {
  tag: 'div',
  title: '',
  breadcrumbsParent: 'Home'
};

export default Page;
