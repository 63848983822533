import {
  useContext,
  createContext,
  useCallback,
  useState,
} from "react";
import { Alert, Fade } from "reactstrap";

export const AuthContext = createContext(null);
export const ProfileContext = createContext(null);
export const AlertContext = createContext();

export function useAuthContext() {
  return useContext(AuthContext);
}

export function useProfileContext() {
  return useContext(ProfileContext);
}

// Auto dispose Queue Alert support multiple alert 
/*
How to apply
import { AlertContext } from "utils/contexts";
const { displayAlert } = useContext(AlertContext);
displayAlert("Hi", "success");
*/
export const AlertProvider = ({ children }) => {
  const [alerts, setAlerts] = useState([]);

  const displayAlert = useCallback((message, type = "success") => {
    const newAlert = { id: Date.now(), message, type };
    setAlerts((currentAlerts) => [...currentAlerts, newAlert]);
    console.log(newAlert);
    setTimeout(() => {
      setAlerts((currentAlerts) =>
        currentAlerts.filter((alert) => alert.id !== newAlert.id)
      );
    }, 5000);
  }, []);

  const defaultMessage = {
    success: "Successful operations",
    danger: "Something went wrong, Please try again",
    "": "This is an alert",
  };

  function getMessage(alert) {
    if (alert?.message) {
      return alert.message;
    }
    return defaultMessage[alert.type] || defaultMessage[""];
  }

  const dismissAlert = useCallback((id) => {
    setAlerts((currentAlerts) =>
      currentAlerts.filter((alert) => alert.id !== id)
    );
  }, []);

  return (
    <AlertContext.Provider value={{ displayAlert }}>
      {children}
      {
        <div
          className="fixed-top container"
          style={{ marginTop: "15px", zIndex: "99999" }}
        >
          {alerts.map((alert) => (
            <Fade key={alert.id} in={true} tag="div">
              <Alert 
                color={alert.type === "success" ? "success" : "danger"}
                toggle={() => dismissAlert(alert.id)}
                >
                {getMessage(alert)}
              </Alert>
            </Fade>
          ))}
        </div>
      }
    </AlertContext.Provider>
  );
};
